import React from "react"

const UtsAgreements = () => {
  return (
    <>
      <div className="col-sm-12">
        <h2>Terms of Services:</h2>
        <p>
          The use of services from Mothersoft Technologies., [hereafter referred
          to as "Mothersoft Technologies" constitutes agreement to these terms.
          You may view our Privacy Policy here.
        </p>
        <h4>1.) Account Setup / Email on file</h4>
        <p>
          We will setup your account after we have received payment and we
          and/or our payment partner's) have screened the order's) in case of
          fraud. It is your responsibility to provide us with an email address
          which is not @ the domain's you are signing up under. If there is ever
          an abuse issue or we need to contact you, the primary email address on
          file will be used for this purpose. It is your responsibility to
          ensure the email address on file is current or up to date at all
          times. If you have a domain name registered with Mothersoft
          Technologies, it is your responsibility to ensure that the contact
          information for your domain account and your actual domain name's is
          correct and up-to-date. Mothersoft Technologies is not responsible for
          a lapsed registration due to outdated contact information being
          associated with the domain. If you need to verify or change this
          information, you should contact our sales team via email. Providing
          false contact information of any kind may result in the termination of
          your account. In dedicated server purchases or high risk transactions,
          it will be necessary to provide government issued identification and
          possibly a scan of the credit card used for the purchase. If you fail
          to meet these requirements, the order may be considered fraudulent in
          nature and be denied. The free transfer services are available for 30
          days from your sign up date. Transfers outside of the 30 day free
          period will incur a charge; please contact a member of the sales
          department with specific details to receive a price quote.
        </p>
        <p>
          The free transfer services are available for 30 days from your sign up
          date. Transfers outside of the 30 day free period will incur a charge;
          please contact a member of the sales department with specific details
          to receive a price quote.
        </p>
        <h4>Transfers</h4>
        <p>
          Our transfers team will make every effort to help you move your site
          to us. However, transfers are provided as a courtesy service, and we
          can not make guarantees regarding the availability, possibility, or
          time required to complete an account transfer. Each hosting company is
          configured differently, and some hosting platforms save data in an
          incompatible or proprietary format, which may make it extremely
          difficult if not impossible to migrate some or all account data. We
          will try our best, but in some cases we may be unable to assist you in
          a transfer of data from an old host.
        </p>
        <h4>1 a.) Domain Agreements</h4>
        <p>
          For Over view of TLDS(Terms for registering a domain)can be found at
          this{" "}
          <a
            href="https://www.mothersoft.in/kb/servlet/KBServlet/faq1375.html"
            target="_blank"
            rel="noreferrer"
          >
            link
          </a>{" "}
          <br></br>
          For Domain related Legal Agreement can be found at this{" "}
          <a
            href="https://www.mothersoft.in/support/legal.php"
            target="_blank"
            rel="noreferrer"
          >
            link
          </a>
          <br></br>
          For General Domain Agreements click{" "}
          <a
            href="https://www.mothersoft.in/servlet/ViewAgreementServlet?requestfor=registraragreement"
            target="_blank"
            rel="noreferrer"
          >
            link
          </a>
          <br></br>
          For .IN Registry Policies click{" "}
          <a
            href="https://registry.in/Policies"
            target="_blank"
            rel="noreferrer"
          >
            link
          </a>
        </p>
        <h4>2.) Content</h4>
        <p>
          {" "}
          All services provided by Mothersoft Technologies may only be used for
          lawful purposes. The laws of the State of Tamilnadu and Government of
          India apply.{" "}
        </p>
        <p>
          {" "}
          The customer agrees to indemnify and hold harmless Mothersoft
          Technologies from any claims resulting from the use of our services.{" "}
        </p>
        <p>
          {" "}
          Use of our services to infringe upon any copyright or trademark is
          prohibited. This includes but is not limited to unauthorized copying
          of music, books, photographs, or any other copyrighted work. The offer
          of sale of any counterfeit merchandise of a trademark holder will
          result in the immediate termination of your account. Any account found
          to be in violation of another's copyright will be expeditiously
          removed, or access to the material disabled. Any account found to be
          in repeated violation of copyright laws will be suspended and/or
          terminated from our hosting. If you believe that your copyright or
          trademark is being infringed upon, please email sales@Mothersoft
          Technologies.com with the information required. A list of required
          information may be found here. If the request is of a licensing issue,
          we may require further documentation.{" "}
        </p>
        <h4>
          Examples of unacceptable material on all Shared and Reseller servers
          include:
        </h4>
        <p>
          * Topsites<br></br>* Nulled, IRC Scripts/Bots<br></br>* Proxy
          Scripts/Anonymizers<br></br>* Pirated Software/Warez<br></br>* Image
          Hosting Scripts (similar to Photobucket or Tinypic)<br></br>*
          AutoSurf/PTC/PTS/PPC sites<br></br>* IP Scanners<br></br>* Bruteforce
          Programs/Scripts/Applications<br></br>* Mail Bombers/Spam Scripts
          <br></br>* Banner-Ad services (commercial banner ad rotation)<br></br>
          * File Dump/Mirror Scripts (similar to rapidshare)<br></br>*
          Commercial Audio Streaming (more than one or two streams)<br></br>*
          Escrow/Bank Debentures<br></br>* High-Yield Interest Programs (HYIP)
          or Related Sites<br></br>* Investment Sites (FOREX, E-Gold Exchange,
          Second Life/Linden Exchange, Ponzi, MLM/Pyramid Scheme)<br></br>* Sale
          of any controlled substance without prior proof of appropriate
          permit(s)<br></br>* Prime Banks Programs<br></br>* Lottery Sites
          <br></br>* MUDs/RPGs/PBBGs<br></br>* Hateful/Racist/Harassment
          oriented sites<br></br>* Hacker focused sites/archives/programs
          <br></br>* Sites promoting illegal activities<br></br>* Forums and/or
          websites that distribute or link to warez/pirated/illegal content
          <br></br>* Bank Debentures/Bank Debenture Trading Programs<br></br>*
          Fraudulent Sites (Including, but not limited to sites listed at
          aa419.org &amp; escrow-fraud.com) <br></br>* Mailer Pro
        </p>
        <h4>
          {" "}
          Examples of unacceptable material on all Dedicated servers include:
        </h4>
        <p>
          * IRCD (irc servers)<br></br>* Nulled, IRC Scripts/Bots<br></br>*
          Pirated Software/Warez<br></br>* IP Scanners<br></br>* Bruteforce
          Programs/Scripts/Applications<br></br>* Mail Bombers/spam Scripts
          <br></br>* Escrow<br></br>* High-Yield Interest Programs (HYIP) or
          Related Sites<br></br>* Investment Sites (FOREX, E-Gold Exchange,
          Second Life/Linden Exchange, Ponzi, MLM/Pyramid Scheme)<br></br>* Sale
          of any controlled substance without prior proof of appropriate
          permit(s)<br></br>* Prime Banks Programs<br></br>* Lottery Sites
          <br></br>* Hateful/Racist/Harassment oriented sites<br></br>* Hacker
          focused sites/archives/programs<br></br>* Sites promoting illegal
          activities<br></br>* Forums and/or websites that distribute or link to
          warez/pirated/illegal content<br></br>* Bank Debentures/Bank Debenture
          Trading Programs<br></br>* Fraudulent Sites (Including, but not
          limited to sites listed at aa419.org &amp; escrow-fraud.com)<br></br>*
          Mailer Pro
        </p>
        <p>
          Mothersoft Technologies services, including all related equipment,
          networks and network devices are provided only for authorized customer
          use. Mothersoft Technologies systems may be monitored for all lawful
          purposes, including to ensure that use is authorized, for management
          of the system, to facilitate protection against unauthorized access,
          and to verify security procedures, survivability, and operational
          security. During monitoring, information may be examined, recorded,
          copied and used for authorized purposes. Use of Mothersoft
          Technologies system's constitutes consent to monitoring for these
          purposes.
        </p>
        <p>
          Any account found connecting to a third party network or system
          without authorization from the third party is subject to suspension.
          Access to networks or systems outside of your direct control must be
          with expressed written consent from the third party. Mothersoft
          Technologies may, at its discretion, request and require documentation
          to prove access to a third party network or system is authorized.
        </p>
        <p>
          We reserve the right to refuse service to anyone. Any material that,
          in our judgment, is obscene, threatening, illegal, or violates our
          terms of service in any manner may be removed from our servers (or
          otherwise disabled), with or without notice.
        </p>
        <p>
          Failure to respond to email from our abuse department within 24 hours
          may result in the suspension or termination of your services. All
          abuse issues must be dealt with via troubleticket/email and will have
          a response within 24 hours.
        </p>
        <p>
          If in doubt regarding the acceptability of your site or service,
          please contact us at{" "}
          <a href="mailto:abuse@Mothersoft Technologies.com">
            abuse@Mothersoft Technologies.com
          </a>{" "}
          and we will be happy to assist you.
        </p>
        <p>
          Potential harm to minors is strictly forbidden, including but not
          limited to child pornography or content perceived to be child
          pornography (Lolita):
        </p>
        <p>
          Any site found to host child pornography or linking to child
          pornography will be suspended immediately without notice.
        </p>
        <h4>Resellers:</h4>
        <p>
          we will suspend the site in question and will notify you so you may
          terminate the account. We will further monitor your activity; more
          than one infraction of this type may result in the immediate
          termination of your account.
        </p>
        <h4>Direct customers:</h4>
        <p>Your services will be terminated with or without notice.</p>
        <p>
          Violations will be reported to the appropriate law enforcement agency.
          It is your responsibility to ensure that scripts/programs installed
          under your account are secure and permissions of directories are set
          properly, regardless of installation method. When at all possible, set
          permissions on most directories to 755 or as restrictive as possible.
          Users are ultimately responsible for all actions taken under their
          account. This includes the compromise of credentials such as user name
          and password. It is required that you use a secure password. If a weak
          password is used, your account may be suspended until you agree to use
          a more secure password. Audits may be done to prevent weak passwords
          from being used. If an audit is performed, and your password is found
          to be weak, we will notify you and allow time for you to change/update
          your password.
        </p>
        <h4>3.) Zero Tolerance Spam Policy</h4>
        <p>
          We take a zero tolerance stance against sending of unsolicited e-mail,
          bulk emailing, and spam. "Safe lists" and purchased lists will be
          treated as spam. Any user who sends out spam will have their account
          terminated with or without notice.
        </p>
        <p>
          Sites advertised via SPAM (Spamvertised) may not be hosted on our
          servers. This provision includes, but is not limited to SPAM sent via
          fax, email, instant messaging, or usenet/newsgroups. No organization
          or entity listed in the ROKSO may be hosted on our servers. Any
          account which results in our IP space being blacklisted will be
          immediately suspended and/or terminated.
        </p>
        <p>
          Mothersoft Technologies reserves the right to require changes or
          disable as necessary any web site, account, database, or other
          component that does not comply with its established policies, or to
          make any such modifications in an emergency at its sole discretion.
        </p>
        <h4>4.) Payment Information</h4>
        <p>4 A.)</p>
        <p>
          You agree to supply appropriate payment for the services received from
          Mothersoft Technologies, in advance of the time period during which
          such services are provided. You agree that until and unless you notify
          Mothersoft Technologies of your desire to cancel any or all services
          received, those services will be billed on a recurring basis. This
          includes when accounts are suspended. When an account's invoices are
          paid outside of the 10 day grace period, invoices will not be
          automatically generated. However, after the customer notifies
          Mothersoft Technologies of his or intent to resume service, invoices
          will be manually generated and payment expected for the time the
          account was suspended.
        </p>
        <p>
          Cancellations must be done in writing via the cancellation form
          provided. Once we receive your cancellation and have confirmed all
          necessary information with you via e-mail, we will inform you in
          writing (typically email) that your account has been canceled. Your
          cancellation confirmation will contain a ticket/tracking number in the
          subject for your reference, and for verification purposes. You should
          immediately receive an automatic "Your request has been received..."
          email with a tracking number. An employee will confirm your request
          (and process your cancellation) shortly thereafter. If you do not hear
          back from us, or do not receive the automatic confirmation email
          within a few minutes after submitting your cancellation, please
          contact us immediately via phone. We require that cancellations of
          service are done through the online form to (a) confirm your identity,
          (b) confirm in writing you are prepared for all files/emails to be
          removed, and (c) document the request. This process reduces the
          likelihood of mistakes, fraudulent/malicious requests, and ensures you
          are aware that the files, emails, and account may be removed
          immediately after a cancellation request is processed.
        </p>{" "}
        <p>
          As a client of Mothersoft Technologies, it is your responsibility to
          ensure that your payment information is up to date, and that all
          invoices are paid on time. You agree that until and unless you notify
          Mothersoft Technologies of your desire to cancel any or all services
          received (by the proper means listed in the appropiate section of the
          Terms of Service), those services will be billed on a recurring basis,
          unless otherwise stated in writing by Mothersoft Technologies.
          Mothersoft Technologies reserves the right to bill your credit card or
          billing information on file with us. Mothersoft Technologies provides
          a 10 day grace period from the time the invoice is generated and when
          it must be paid. Any invoice that is outstanding for 10 days and not
          paid will result in a Rs.500 late fee and/or an account suspension
          until account balance has been paid in full. The Rs.500 late fee is
          applied in addition to whatever else is owed to Mothersoft
          Technologies for services rendered. Access to the account will not be
          restored until payment has been received.
        </p>
        <p>
          It is the customer's responsibility to notify our sales team via email
          at sales@Mothersoft Technologies.com after paying for a domain renewal
          and to ensure the email is received and acted upon. Domain renewal
          notices and invoices are provided as a courtesy reminder and
          Mothersoft Technologies cannot be held responsible for failure to
          renew a domain or failure to notify a customer about a domain's
          renewal.
        </p>
        <p>
          Invoices that have been paid more than once with multiple Paypal
          Subscriptions can only be added as credit towards the account and
          cannot be refunded via Paypal. If you require assistance with this
          provision, please contact sales@Mothersoft Technologies.com
        </p>
        <p>
          Mothersoft Technologies reserves the right to change the monthly
          payment amount and any other charges at anytime.
        </p>
        <p>
          4 B.)&nbsp; For India Customers, Incase of TDS or other Deductions.
          Inorder to get credit, must provide the TDS certificates.
        </p>
        <p>
          4 C.)&nbsp; Prices of the software license fees will be revised
          whenever the vendor increases or due to currency fluctuations, etc.,
          If paid in advance a seperate invoice will be raised for the balance
          period and that should be paid immediately, failure of such payments
          will invite suspension / termination of licenses.{" "}
        </p>
        <h4>5.) Backups and Data Loss</h4>
        <p>
          Your use of this service is at your sole risk. Our backup service is
          provided to you as a courtesy. Mothersoft Technologies performs weekly
          backups of shared and reseller servers; however, these backups are for
          Mothersoft Technologies's administrative purposes only, and are in NO
          WAY GUARANTEED! Customers are responsible for maintaining their own
          backups on their own personal computers. Mothersoft Technologies does
          not provide any sort of compensation for lost or incomplete data in
          the event that backups do not function properly (even if the
          malfunction was due to negligence on Mothersoft Technologies's part).
          We will do our best to ensure complete and accurate backups, but
          assume no responsibility for this duty. Always backup your site
          contents to your personal computer! We make no guarantees about the
          availability of backups. You agree to take full responsibility for
          files and data transferred and to maintain all appropriate backup of
          files and data stored on Mothersoft Technologies servers.{" "}
        </p>
        <h4>Backup Restore</h4>
        <p>
          The backup restore service are not included in your Hosting Fees and
          hence there will be an admin fees of Rs.500/- or $10 per instance in
          any event if you want us to restore your website from our weekly
          backup respository. The admin fees are payable immediately and are
          non-refundable. We cannot guarantee the integrity of the backup on our
          weekly backup repository. Backups will not be provided for accounts
          that have been suspended or terminated for any reason unless otherwise
          agreed to in writing by Mothersoft Technologies.
        </p>
        <h4>6.) Cancellations and Refunds</h4>
        <p>
          Mothersoft Technologies reserves the right to cancel, suspend, or
          otherwise restrict access to the account at any time with or without
          notice.
        </p>
        <p>
          Any abuse on our staff in any medium or format will result in the
          suspension or termination of your services.
        </p>
        <p>
          Customers may cancel at any time via a cancellation form. Mothersoft
          Technologies gives you an unconditional 60 days money back guarantee
          on shared hosting, and reseller hosting for any customer who paid the
          first invoice with a credit card or with Paypal.
        </p>
        <h4>
          The following methods of payments are non-refundable, and refunds will
          be posted as credit to the hosting account:
        </h4>
        <p>
          * Bank Wire /NEFT/RTGS Transfers<br></br>* Western Union Payments
          <br></br>* Cheques / Demand Drafts / Payorders<br></br>* Money orders
          <br></br>* Cash Payments
        </p>
        <p>
          {" "}
          There are no refunds on VPS, dedicated servers, administrative fees,
          install fees for custom software, or domain name purchases. Refund
          requests for shared and reseller accounts after the initial 60 days
          will not be entertained.{" "}
        </p>
        <p>
          {" "}
          Only first-time accounts are eligible for a refund. For example, if
          you've had an account with us before, canceled and signed up again,
          you will not be eligible for a refund or if you have opened a second
          account with us.{" "}
        </p>
        <p>
          {" "}
          Violations of the Terms of Service will waive the refund policy.{" "}
        </p>
        <p> GST and Gateway fee if any will be deducted before the refund.</p>
        <h4>7a.) Resource Usage</h4>
        <p> User may not:</p>
        <p>
          a) Use 20% or more of system resources for longer then 90 seconds.
          There are numerous activities that could cause such problems; these
          include: CGI scripts, FTP, PHP, HTTP, etc.<br></br>
          b) Run stand-alone, unattended server-side processes at any point in
          time on the server. This includes any and all daemons, such as IRCD.
          <br></br>
          c) Run any type of web spider or indexer (including Google Cash /
          AdSpy) on shared servers.<br></br>
          d) Run any software that interfaces with an IRC (Internet Relay Chat)
          network.<br></br>
          e) Run any bit torrent application, tracker, or client. You may link
          to legal torrents off-site, but may not host or store them on our
          shared servers.<br></br>
          f) Participate in any file-sharing/peer-to-peer activities<br></br>
          g) Run any gaming servers such as counter-strike, half-life,
          battlefield1942, etc<br></br>
          h) Run cron entries with intervals of less than 15 minutes.<br></br>
          i) When using PHP include functions for including a local file,
          include the local file rather than the URL. Instead of
          include("http://yourdomain.com/include.php") use
          include("include.php")<br></br>
          j) Any accounts that is created without updating the nameserver or
          with out a DNS records Pointing to our servers will be removed with or
          without Notice.{" "}
        </p>
        <h4>Shared Hosting as Backup / Storage System</h4>
        <p>
          {" "}
          Mothersoft Technologies shared hosting / reseller hosting plans are
          not allowed be used as a backup/storage/archive system. One
          cPanel/Plesk backup for that same account is allowed to be stored for
          no more than three calendar days. Please note that shared hosting /
          reseller hosting plans are for website hosting, and the storage of
          backups, file repositories, MP3s, videos, photo archives, email
          archives, file archives and similar is strictly prohibited. Violators
          of this policy may lead their accounts terminated without notice and
          refund.
        </p>
        <p>
          {" "}
          The Unlimited Policy doesn't apply to email/sql. Every unlimited
          shared hosting plan will have a 2GB limit for email storage and will
          be enforced strictly. sql have 2GB limit , Accounts found abusing our
          servers as email storage will be suspended / terminated with or
          without notice.
        </p>
        <p>
          {" "}
          Every domain hosted under reseller accounts will have a 2GB limit for
          email storage and accounts exceeding 2GB will be suspended with or
          without notice.
        </p>
        <h4>Reselling</h4>
        <p>
          {" "}
          Reselling is not allowed on Shared hosting plans. If reselling is
          desired, a reseller plan must be purchased.
        </p>
        <h4>Adult Content</h4>
        <p>Pornography in any form is strictly prohibited in our servers. </p>
        <h4>Chat Applications</h4>
        <p>
          All interactive chat web applications are strictly prohibited on
          shared / reseller hosting plans. This includes but is not limited to
          chat applications written in PHP, Perl, CGI, Python, Ruby, etc. in
          addition to IRC or IRC bots. Any web application that provides
          'real-time' chat is not permitted. Shoutboxes, chatboxes, live
          chat/live help (such as but not limited to LiveZilla) and other web
          based chat programs are prohibited.
        </p>
        <h4>Web Applications &amp; Web Accessible Scripts</h4>
        <p>
          All web applications that are out of date and actively being exploited
          will be shut down immediately without notice. As a webmaster you
          should evaluate your web based applications and scripts on a regular
          basis to ensure they're secure and up to date. Blogs, CMS, Forums and
          any applications using a commenting system should have some form of
          spam protection or moderation. CAPTCHA is a popular way to eliminate
          spam robots from spamming your website.{" "}
        </p>
        <h4>7b.) INODES</h4>
        <p>
          The use of more than 50,000 inodes on any shared account may
          potentially result in a warning first, and if no action is taken
          future suspension. Accounts found to be exceeding the 30,000 inode
          limit will automatically be removed from our backup system to avoid
          over-usage. Every file (a webpage, image file, email, etc) on your
          account uses up 1 inode.
        </p>
        <p>
          Sites that slightly exceed our inode limits are unlikely to be
          suspended; however, accounts that constantly create and delete large
          numbers of files on a regular basis, have hundreds of thousands of
          files, or cause file system damage may be flagged for review and/or
          suspension. The primary cause of excessive inodes seems to be due to
          users leaving their catchall address enabled, but never checking their
          primary account mailbox. Over time, tens of thousands of messages (or
          more) build up, eventually pushing the account past our inode limit.
          To disable your default mailbox, login to cPanel and choose "Mail",
          then "Default Address", "Set Default Address", and then type in:
          :fail: No such user here.{" "}
        </p>
        <h4>7c.) Backup Limit</h4>
        <p>
          {" "}
          Any shared account using more than 2 gigs of disk space will be
          removed from our off site weekly backup with the exception of
          Databases continuing to be backed up. All data will continue to be
          mirrored to a secondary drive which helps protect against data loss in
          the event of a drive failure.{" "}
        </p>
        <h4>8.) Bandwidth Usage</h4>
        <p>
          {" "}
          You are allocated a monthly bandwidth allowance. This allowance varies
          depending on the hosting package you purchase. Should your account
          pass the allocated amount we reserve the right to suspend the account
          until the start of the next allocation, suspend the account until more
          bandwidth is purchased at an additional fee, suspend the account until
          you upgrade to a higher level of package, terminate the account and/or
          charge you an additional fee for the overages. Unused transfer in one
          month cannot be carried over to the next month.{" "}
        </p>
        <h4> 9a.) Money back Guarantee</h4>
        <p>
          {" "}
          Each of Mothersoft Technologies's managed shared and reseller hosting
          plans carry a 60 days unconditional money back guarantee on them. If
          you are not completely satisfied with our services within the first 60
          days of your service, you will be given a full refund of the contract
          amount. Remember, this is only for monthly shared or reseller packages
          and does not apply to VPS, dedicated servers, administrative fees,
          install fees for custom software, or domain name purchases.
        </p>
        <p>
          {" "}
          Mothersoft Technologies will not activate new orders or activate new
          packages for customers who have an outstanding balance with Mothersoft
          Technologies. For a new order to be setup or a new package to be
          activated, you must have a balance of Rs.0.00, unless otherwise stated
          by Mothersoft Technologies in writing.{" "}
        </p>
        <h4> 9b.) Uptime Guarantee</h4>
        <p>
          {" "}
          If your shared / reseller server has a physical downtime that is not
          within the 99% uptime you may receive one month of credit on your
          account. Approval of the credit is at the discretion of Mothersoft
          Technologies dependant upon justification provided. Third party
          monitoring service reports may not be used for justification due to a
          variety of factors including the monitor's network capacity/transit
          availability. The uptime of the server is defined as the reported
          uptime from the operating system and the Apache Web Server which may
          differ from the uptime reported by other individual services. To
          request a credit, please contact sales@Mothersoft Technologies.com
          with justification. All requests must be made in writing via email.
          Uptime guarantees only apply to shared / reseller solutions. Dedicated
          servers are covered by a network guarantee in which the credit is
          prorated for the amount of time the server is down which is not
          related to our uptime guarantee.{" "}
        </p>
        <h4> 10.) Reseller: Client Responsibility</h4>
        <p>
          {" "}
          Resellers are responsible for supporting their clients. Mothersoft
          Technologies does not provide support to our Reseller's Clients. If a
          reseller's client contacts us, we reserve the right to place the
          client account on hold until the reseller can assume their
          responsibility for their client. All support requests must be made by
          the reseller on their clients' behalf for security purposes. Resellers
          are also responsible for all content stored or transmitted under their
          reseller account and the actions of their clients'. Mothersoft
          Technologies will hold any reseller responsible for any of their
          clients actions that violate the law or the terms of service.{" "}
        </p>
        <h4> 11.) Shared (non-reseller accounts) / Semi dedicated Servers</h4>
        <p>
          {" "}
          Shared accounts may not resell web hosting to other people, if you
          wish to resell hosting you must use a reseller account. Semi-dedicated
          servers are not backed up. You must maintain your own backups.{" "}
        </p>
        <h4> 12.) Dedicated Servers</h4>
        <p>
          {" "}
          Mothersoft Technologies reserves the right to reset the password on a
          dedicated server if the password on file is not current so that we may
          do security audits as required by our datacenter. It is the
          responsibility of the client to ensure that there is a valid email
          address and current root password on file for their dedicated server
          on file to prevent downtime from forced password resets. Mothersoft
          Technologies reserves the right to audit servers as needed and to
          perform administrative actions at the request of our datacenter.
          Dedicated servers are NOT backed up by us and it is the responsibility
          of the client to maintain backups or have a solution for this. You may
          purchase an additional hard drive and maintain backups to it as the
          most simple solution. Please contact sales@Mothersoft Technologies.com
          if you wish to obtain a secondary hard drive. It is your
          responsibility to maintain backups.{" "}
        </p>
        <h4> 13.) Price Change</h4>
        <p>
          {" "}
          We reserve the right to change prices listed on Mothersoft
          Technologies.com, and the right to increase / decrease the amount of
          resources given to plans at any time with or without notice. The Price
          which you see on final step of the Order Page is your final Price.{" "}
        </p>
        <h4> 14.) Coupons</h4>
        <p>
          {" "}
          Discounts and coupon codes are reserved for first-time accounts or
          first-time customers only and may not be used towards the purchase of
          a domain registration unless otherwise specified. If you have signed
          up using a particular domain, you may not resign up for that domain
          using another coupon at a later date. Any account found in violation
          of these policies will be reviewed by our sales department and the
          appropriate invoices will be added to the account. Coupon abuse will
          not be tolerated and may result in the suspension or termination of
          the account.{" "}
        </p>
        <h4> 15a.) Indemnification</h4>
        <p>
          {" "}
          Customer agrees that it shall defend, indemnify, save and hold
          Mothersoft Technologies harmless from any and all demands,
          liabilities, losses, costs and claims, including reasonable attorney's
          fees asserted against Mothersoft Technologies, its agents, its
          customers, officers and employees, that may arise or result from any
          service provided or performed or agreed to be performed or any product
          sold by customer, its agents, employees or assigns. Customer agrees to
          defend, indemnify and hold harmless Mothersoft Technologies against
          liabilities arising out of; (1) any injury to person or property
          caused by any products sold or otherwise distributed in connection
          with Mothersoft Technologies; (2) any material supplied by customer
          infringing or allegedly infringing on the proprietary rights of a
          third party; (3) copyright infringement and (4) any defective products
          sold to customers from Mothersoft Technologies's server.{" "}
        </p>
        <h4> 15b.) Jurisdiction</h4>
        <p>
          The laws of the Republic of India shall govern this agreement.
          <br></br>
          In any event of dispute the area of Jurisdiction would be Chennai,
          Tamilnadu only..{" "}
        </p>
        <h4> 16.) Disclaimer</h4>
        <p>
          {" "}
          Mothersoft Technologies shall not be responsible for any damages your
          business may suffer. Mothersoft Technologies makes no warranties of
          any kind, expressed or implied for services we provide. Mothersoft
          Technologies disclaims any warranty or merchantability or fitness for
          a particular purpose. This includes loss of data resulting from
          delays, no deliveries, wrong delivery, and any and all service
          interruptions caused by Mothersoft Technologies and its employees.{" "}
        </p>
        <h4> 17.) Disclosure to law enforcement</h4>
        <p>
          {" "}
          Mothersoft Technologies may disclose any subscriber information to law
          enforcement agencies without further consent or notification to the
          subscriber upon lawful request from such agencies. We will cooperate
          fully with law enforcement agencies.{" "}
        </p>
        <h4> 18.) Changes to the TOS</h4>
        <p>
          {" "}
          Mothersoft Technologies reserves the right to revise its policies at
          any time without notice.{" "}
        </p>
      </div>
    </>
  )
}

export default UtsAgreements
